// extracted by mini-css-extract-plugin
export var column = "EnzuServicesOverview__column__a34QB";
export var flex = "EnzuServicesOverview__flex__yGsz8";
export var flexColumn = "EnzuServicesOverview__flexColumn__UkjoY";
export var gap1 = "EnzuServicesOverview__gap1__sjq2P";
export var gap2 = "EnzuServicesOverview__gap2__HXYDP";
export var gap3 = "EnzuServicesOverview__gap3__WYXYK";
export var gap4 = "EnzuServicesOverview__gap4__LIV3n";
export var gap5 = "EnzuServicesOverview__gap5__SZELB";
export var row = "EnzuServicesOverview__row__tRHvq";
export var service = "EnzuServicesOverview__service__E5Uq7";
export var serviceDetails = "EnzuServicesOverview__serviceDetails__iD7JT";
export var servicesOverview = "EnzuServicesOverview__servicesOverview__nhwcJ";