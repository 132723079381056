// extracted by mini-css-extract-plugin
export var anchor = "EnzuBareMetal__anchor__E3CGA";
export var backgroundImage = "EnzuBareMetal__backgroundImage__w4kKp";
export var cloudProviderIconsBareMetalSection = "EnzuBareMetal__cloudProviderIconsBareMetalSection__lXkp8";
export var column = "EnzuBareMetal__column__TBS_Q";
export var completeControlWithBareMetalSection = "EnzuBareMetal__completeControlWithBareMetalSection__PPcog";
export var detailsContent = "EnzuBareMetal__detailsContent__sFGKt";
export var detailsSection = "EnzuBareMetal__detailsSection__YfxBV";
export var detailsSidebar = "EnzuBareMetal__detailsSidebar__cjgxf";
export var flex = "EnzuBareMetal__flex__w_ofN";
export var flexColumn = "EnzuBareMetal__flexColumn___N6j5";
export var gap1 = "EnzuBareMetal__gap1__E3kk8";
export var gap2 = "EnzuBareMetal__gap2__Q6gJL";
export var gap3 = "EnzuBareMetal__gap3__xJt3I";
export var gap4 = "EnzuBareMetal__gap4__e19jt";
export var gap5 = "EnzuBareMetal__gap5__Hu3pV";
export var jumbotronSection = "EnzuBareMetal__jumbotronSection__GnSYw";
export var map = "EnzuBareMetal__map__F4J1p";
export var pricingAndConfigurationsSection = "EnzuBareMetal__pricingAndConfigurationsSection__P6yvC";
export var pricingAndConfigurationsSectionContainer = "EnzuBareMetal__pricingAndConfigurationsSectionContainer__MkBIq";
export var requestEstimateBareMetalSection = "EnzuBareMetal__requestEstimateBareMetalSection__uHM2n";
export var row = "EnzuBareMetal__row__xA15o";
export var signUpTodaySection = "EnzuBareMetal__signUpTodaySection__BTvvS";
export var sticky = "EnzuBareMetal__sticky__IgG6u";
export var subNavigationBareMetalSection = "EnzuBareMetal__subNavigationBareMetalSection__aMxEL";
export var whyBareMetalSection = "EnzuBareMetal__whyBareMetalSection__D4zvc";
export var wideContainer = "EnzuBareMetal__wideContainer__dj5cd";
export var wrapper = "EnzuBareMetal__wrapper__nw6JJ";