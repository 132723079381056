// extracted by mini-css-extract-plugin
export var column = "JumbotronEnzu__column__O19QX";
export var flex = "JumbotronEnzu__flex__uMlX1";
export var flexColumn = "JumbotronEnzu__flexColumn__XS1Xp";
export var gap1 = "JumbotronEnzu__gap1__WRhB3";
export var gap2 = "JumbotronEnzu__gap2__ZAUgW";
export var gap3 = "JumbotronEnzu__gap3__hp2Cb";
export var gap4 = "JumbotronEnzu__gap4__Q_oca";
export var gap5 = "JumbotronEnzu__gap5__Fm62J";
export var heading = "JumbotronEnzu__heading__KneRD";
export var providerIcons = "JumbotronEnzu__providerIcons__REhP2";
export var row = "JumbotronEnzu__row__N2ddP";
export var subheading = "JumbotronEnzu__subheading__HwsfI";
export var wrapper = "JumbotronEnzu__wrapper__ueL7T";